import VueRouter from 'vue-router'

import Home from '@/components/Home'

const routes = [

  { path: '/', component: Home },
  { path: '*', redirect: "/" },
]

const router = new VueRouter({
  routes
})

export default router