<template>
  <amplify-authenticator>
    <v-app>
      <div>
        <img class="mt-2" src="../assets/bg.jpg" width="100%" />
        <div v-if="countDownTimer !== ''" align="center">
          <h2 class="mt-5"><span class="bg-colo-bmx white--text pa-1">Vous n'êtes pas encore inscrit ?</span></h2>
          <h2 class="mt-3"><a href="https://forms.office.com/r/gbzzE5mP4y"
              class="bg-colo-bmx white--text pa-1 text-deco-none" target="blank">Cliquez-ici</a></h2><br>
          <h2 class="display-1 font-weight-bold color-bmx">{{ countDownTimer }}</h2>
          <h2 class="display-1 font-weight-bold color-bmx">AVANT LE DEBUT DE L'EVENEMENT</h2>
        </div>
        <div id="live" v-else-if="countDownTimer == ''">
          <v-container fluid padless>
            <v-row align="top" justify="center">
              <v-col cols="2"></v-col>
              <v-col cols="5">
                <div ref="player" class="container inner player-wrapper">
                  <div class="pos-absolute full-width full-height top-0">
                    <video id="video-player" class="el-player" poster="./../assets/coming-soon-black.png" controls
                      playsinline></video>
                    <div>En fonction de votre navigateur Internet, merci de vérifier que le son est bien activé sur le
                      lecteur
                      vidéo. <v-img class="mx-auto" src="../assets/volume.png" max-height="32" max-width="32"></v-img>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" style="text-align: center">
                <v-card outlined shaped elevation="15" width="390" height="380" class="mx-auto mt-9 ">
                  <v-card-text class="text-lg-h6 text-center mr-2">Pour poser vos questions en direct : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br> 
                    scannez ce QR Code &nbsp;&nbsp;&nbsp;&nbsp;</v-card-text>
                  <v-img class="mx-auto" src="../assets/qr.png" max-height="120" max-width="120"></v-img>
                  <v-card-text class="text-lg-h6 text-center pr-2">
                    ou allez sur &nbsp;&nbsp;&nbsp;&nbsp;<br>
                    <strong>www.menti.com &nbsp;&nbsp;&nbsp;&nbsp;</strong> <br>
                    et entrez le code &nbsp;&nbsp;&nbsp;&nbsp;<br>
                    <strong>1673 1751 &nbsp;&nbsp;&nbsp;&nbsp;</strong>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="bg2 pb-10">
          <v-container>
            <v-row justify="center">
              <v-col cols="1"></v-col>
              <v-col cols="4" class="text-center" justify="center">
                <div class="title-block2 mt-10" justify="center">
                  <v-btn text @click="displayOrHide('displayProgram')"
                    class="white--text mt-2 mb-2 display-1 font-weight-bold">PROGRAMME</v-btn>
                </div>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4" class="text-center" justify="center">
                <div class="title-block2 mt-10" justify="center">
                  <v-btn text @click="displayOrHide('displayIntervenants')"
                    class="white--text mt-2 mb-2 display-1 font-weight-bold">INTERVENANTS</v-btn>
                </div>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8" class="white--text pr-3 text-center" justify="center">
                <h3>
                  Journée animée par Pierre DEVAUX – Directeur Scientifique – THERAXEL
                </h3>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <div v-if="displayProgram" class="program">
              <v-expansion-panels class="mb-6">
                <v-row class="mt-12" v-for="program in programs" :key="program.id">
                  <v-col class="mt-8 text-right" cols="2">
                    <h4 class="white--text pr-3" style="font-size: 1.5em">
                      {{ program.hour }}
                    </h4>
                  </v-col>
                  <v-col cols="9" style="text-align: left">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold color-bmx pt-12" style="background-color: white">{{
                          program.title
                      }}</v-col>
                      <v-col cols="8" style="background-color: white">
                        <v-expansion-panel>
                          <v-expansion-panel-header class="pl-0" :hide-actions="!program.isSummary"
                            style="color: #00427f" expand-icon="mdi-menu-down">
                            <span class="program-header pr-3">{{ program.header }}</span>
                            <template v-slot:actions>
                              <v-btn small color="#00427f" dark>Voir le résumé</v-btn><br><br>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            {{ program.text }}
                            <div class="mt-2" v-if="program.list">
                              <p class="ma-0" v-for="item in program.list" :key="item">
                                {{ item }}
                              </p>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <p class="color-bmx mt-2 font-weight-bold">
                          {{ program.presenter }}
                        </p>
                        <p align="right">
                          <v-btn small @click="scroll('live')" color="#299123" dark>Live</v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panels>
            </div>
            <div v-if="displayIntervenants" class="mt-12 intervenants">
              <v-row v-for="presenter in presenters" :key="presenter.id">
                <v-col cols="1"></v-col>
                <v-col cols="2" class="pt-5 ma-0">
                  <img class="ml-15 mt-4" :src="getImgUrl(presenter.photoName)" v-bind:alt="presenter.photoName"
                    width="50%" />
                  <h3 class="pa-0 mt-0 mb-0 ml-15 white--text">
                    {{ presenter.order }}
                  </h3>
                </v-col>
                <v-col cols="8">
                  <v-card max-width="1000" outlined>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{ presenter.company }}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                          {{ presenter.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                            presenter.job
                        }}</v-list-item-subtitle>
                        <p class="mt-2 text-left">{{ presenter.experience }}</p>
                      </v-list-item-content>
                    </v-list-item>

                    <v-card-actions>
                      <a :href="`${linkedInUrl}/${presenter.linkedInID}`" class="text-deco-none" target="blank">
                        <v-btn color="#00427F" outlined rounded text class="btn-link">
                          <v-icon> mdi-linkedin </v-icon>
                          Accèder au profil LinkedIn
                        </v-btn>
                      </a>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
      </div>
      <v-footer class="mt-10" color="white">
        <v-row class="mb-5">
          <v-col cols="3">
            <p class="ml-7" style="font-size: 1.5em; text-align: left; color: #a4a7c0">
              PIONEERING DIAGNOSTICS
            </p>
          </v-col>
          <v-col cols="5"></v-col>
          <v-col cols="3" class="font-weight-bold" style="text-align: right">
            <div>
              <a href="https://www.biomerieux-industry.com/fr/legal-notice" target="blank">
                <span  style="color: #00427f">Politique de confidentialité</span>
              </a>
            </div>
            <div class="mt-2">
              <a  href="https://www.biomerieux-industry.com/fr" target="blank">
                <span style="color: #00427f">En savoir plus sur bioMérieux</span>
              </a>
            </div>
            <div class="mt-2">
              <a href="mailto:lise.losseff@biomerieux.com" target="blank">
                <span style="color: #00427f">Un problème ? lise.losseff@biomerieux.com</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </amplify-authenticator>
</template>

<script>
import {
  PlayerEventType,
  PlayerState
} from "amazon-ivs-player";

export default {
  name: "Home",
  props: {
    msg: String,
    player: null
  },

  data: () => ({
    // countDownDate: new Date("Jun 9, 2022 08:45:00").getTime(),
    countDownDate: new Date("May 31, 2022 15:33:00").getTime(),
    countDownTimer: "",
    displayProgram: true,
    linkedInUrl: "https://www.linkedin.com/in",
    displayIntervenants: false,
    programs: [
      {
        id: 1,
        hour: "9h00 - 9h45",
        title: "Conférence #1",
        presenter: "Thierry Bonnevay - SANOFI | Laurent Leblanc – bioMérieux",
        header:
          "Suitability of a Single Incubation Temperature for Environnemental Monitoring program",
        isSummary: true,
        list: [],
        text: "The Environmental Monitoring program is an important GMP control in the pharmaceutical manufacturing operations. The most common practice is doing sequential incubation time and temperature to maximize the recovery of the microbial flora. The current protocol starts with low temperature (22,5°) to enhance the growth of environmental strains, such as moulds, then switching to higher temperature (32,5°C) to recover human skin microorganisms. The single temperature PDA task force initiative (One media / One temperature) investigates the possibility to move from two-temperature to a single temperature incubation, targeting an intermediate incubation temperature of 27,5°C, however, currently there is no consensus in the scientific community, regarding the optimal incubation conditions for microbial recovery. We report here a collaborative work between bioMérieux and Sanofi-Pasteur performed to evaluate the growth of microorganisms recovered from the manufacturing environment at different incubation temperatures. The behavior of microbes was tested using an automated plates incubator/reader to follow the growth evolution over time. We studied 4 incubations temperatures at dual or single incubation. The data generated permitted to assess the discrepancies of the growth observed for the different method and highlighted interesting findings on the suitability of the single temperature incubation in a routine industrial context.",
      },
      {
        id: 2,
        hour: "9h45 - 10h30",
        title: "Conférence #2",
        presenter: "Philippe DUTOT – NOVONORDISK",
        header:
          "Evolution de la température d’incubation utilisée pour le monitoring environnemental sur un site de production afin de répondre aux attentes règlementaires et impact sur les résultats",
        isSummary: true,
        list: [
          "-  Etude 1 : Incubation unique à 20-25°C versus incubation unique à 30-35°C",
          "-  Etude 2 : Double incubation 20-25°C puis 30-35°C versus incubation unique à 30-35°C",
        ],
        text: "A la suite de différents écarts relevés lors d’inspections réglementaires, relatif aux conditions d’incubation utilisées pour les échantillons du monitoring environnemental sur un site de production aseptique du groupe, plusieurs études comparatives ont été réalisées et les résultats obtenus seront présentés :",
      },
      {
        id: 3,
        hour: "11h00 - 11h45",
        title: "Conférence #3",
        presenter: "Guillaume Pinon – SERVIER",
        header:
          "Mise en place d’un monitoring environnemental lors de la création d’une nouvelle unité de biotechnologie (fabrication et remplissage aseptique)",
        isSummary: true,
        list: [],
        text: "La mise en place d’un monitoring environnemental lors de la création d’une nouvelle unité est un moment crucial et délicat car soumis à plusieurs facteurs inconnus (flore, résultat de qualification…). Cette présentation détaillera la conception du programme et la justification des choix (séquence d’incubation, choix des points) pour la qualification puis le monitoring de routine.",
      },
      {
        id: 4,
        hour: "11h45 - 12h30",
        title: "Conférence #4",
        presenter: "Hoang Pham - ABL Europe",
        header:
          "Monitoring Environnemental - De la gestion à l’exploitation des données",
        isSummary: true,
        list: [
          "-	Utilisation de « gabarit » de saisie afin sécuriser et faciliter le report des données dans Excel",
          "-	Utilisation du logiciel Power BI pour exploiter et présenter facilement et rapidement les données et de façon fiable et dynamique",
          "-	Interprétation et utilisation des analyses de tendance pour évaluer, connaître et maîtriser la contamination microbiologique des ZAC",
        ],
        text: "",
      },
      {
        id: 5,
        hour: "14h00 - 14h45",
        title: "Table ronde",
        presenter: "Intervenants du matin",
        header:
          "Monitoring Environnemental et Analyses de tendance associées : Echanges et discussions sur la mise en application des exigences du chapitre 9 de la nouvelle annexe 1 des EUGmp",
        isSummary: true,
        list: [],
        text: "Il ne fait aucun doute que les 4 présentations de la matinée vont se faire écho et générer des questions communes aux cinq intervenants. A travers ce moment d’échanges dédié, nous vous offrons la possibilité de partager vos idées et vos retours d’expériences et d’améliorer votre compréhension et les subtilités pour les différentes sujets évoqués et ce, en lien avec les attentes du nouveau texte de l’Annexe 1. Votre participation active permettra de nourrir le débat. Nous comptons sur vous !",
      },
      {
        id: 6,
        hour: "14h45 - 15h30",
        title: "Conférence #5",
        presenter: "Samah Ringa – VEOLIA",
        header:
          "Maîtrise de la contamination dans les boucles d’eau pharmaceutique : Bonnes pratiques de conception, surveillance microbiologique et prévention des biofilms",
        isSummary: true,
        list: [],
        text: "Après un bref rappel réglementaire sur les eaux pharmaceutiques et des notions sur le processus de formation de biofilm, nous aborderons les principales techniques de détection et de surveillance. le corps du sujet se concentrera essentiellement sur les régles de l'art en matière de conception des installations de production, stockage et de distribution pour éviter la prolifération microbienne ainsi que des solutions pour prévenir la formation de biofilm.",
      },
      {
        id: 7,
        hour: "15h30 - 16h15",
        title: "Conférence #6",
        presenter: "Jean-Denis Mallet - PHARMAPLAN",
        header:
          "Contamination progressive d’un système de traitement d’eau et moyens de s’y opposer (Inspiré d’un cas réel)",
        isSummary: true,
        list: [],
        text: "Alors qu’elle utilise depuis plusieurs années une source d’eau « brute », une entité de production pharmaceutique constate une dégradation à bas bruit de la qualité microbiologique (du point de vue quantitatif) de cette eau sans détecter que le profil qualitatif a évolué. Cette prise de conscience ne se fait qu’au moment où le contrôle retrouve des bactéries « objectionables » dans l’eau issue du prétraitement. Commence alors une lutte pour éviter l’envahissement de l’ensemble de la chaîne de traitement d’eau. Toute ressemblance avec un cas réel ne serait pas tout à fait fortuite.",
      },
    ],
    presenters: [
      {
        id: 1,
        name: "Thierry BONNEVAY",
        photoName: "thierry-bonnevay",
        company: "SANOFI",
        job: "Global Analytical Microbiology Expert",
        order: "Conférence #1",
        linkedInID: "thierry-bonnevay-0341904/",
        experience:
          "Thierry Bonnevay has more than 25 years of experience in the field of microbiological quality control, acquired within the Sanofi Vaccine and has held various positions in this field both in industrial affairs and in R&D. He is a member of the European Pharmacopoeia Expert Group EDQM within Group 1 Microbiology (since 2016), the Bacterial Endotoxins Working Group (WP BET) since 2017 and the Mycoplasma Working Group (WP MYC) since 2018. Finally, he is part of the board of the French association A3P since 2017.",
      },
      {
        id: 2,
        name: "Laurent LEBLANC",
        photoName: "laurent-leblanc",
        company: "BIOMERIEUX",
        job: "Responsable laboratoire microbiologie",
        order: "Conférence #1",
        linkedInID: "laurent-leblanc-51599635",
        experience:
          "Laurent Leblanc est titulaire d’un Master en Biotechnologie et à 20 ans d’expérience dans le domaine de la microbiologie. Il a travaillé dans de nombreuses entreprises du secteur pharmaceutique et à rejoint bioMérieux en 2008. Il est aujourd’hui responsable d’un laboratoire de microbiologie spécialisé dans le développement des solutions techniques destinées au marché pharmaceutique.",
      },
      {
        id: 3,
        name: "Philippe DUTOT",
        photoName: "philippe-dutot",
        company: "NOVONORDISK",
        job: "Spécialiste Assurance de Stérilité",
        order: "Conférence #2",
        linkedInID: "philippe-dutot-4020a09",
        experience:
          "Diplôme : Ingénieur en Biotechnologie et Docteur en Biologie moléculaire et cellulaire. Plus de 25 ans d’expérience professionnelle dans l’industrie pharmaceutique sur des sites de production aseptique et à différentes fonctions (R&D, production pilote, Contrôle Qualité, Assurance Qualité).",
      },
      {
        id: 4,
        name: "Guillaume PINON",
        photoName: "guillaume-pinon",
        company: "SERVIER",
        job: "Responsable Laboratoire Conformité et Développement Microbiologique",
        order: "Conférence #3",
        linkedInID: "guillaume-pinon-879067113",
        experience:
          "Spécialiste dans le domaine de la microbiologie industrielle sur les produits de santé, j’ai travaillé aussi bien dans le domaine de la prestation de service spécialisée en microbiologie que sur des sites de production d’injectables stériles et sur des sites de production non stérile (liquide, solide, pâteux) en tant que responsable microbiologie.",
      },
      {
        id: 5,
        name: "Hoang PHAM",
        photoName: "hoang-pham",
        company: "ABL",
        job: "Head Of Quality Control",
        order: "Conférence #4",
        linkedInID: "hoang-pham-877b5534",
        experience:
          "Pharmacien thésé en 2000 – DESS en Contrôle Qualité Microbiologique. Pharmacien Délégué – Responsable du CQ des sites de Strasbourg et de Lyon d’ABL Europe. Plus de 20 ans en Contrôle Qualité en industrie pharmaceutique : Octapharma, MSD, Virbac, Merck, BTT – Synerlab.",
      },
      {
        id: 6,
        name: "Samah RINGA",
        photoName: "samah-ringa",
        company: "VEOLIA",
        job: "Life Sciences Market Director",
        order: "Conférence #5",
        linkedInID: "samah-ringa",
        experience: "Responsable AQ opérationnelle (8 ans) puis spécialisation dans les systèmes de production des eaux à usage eaux pharmaceutique (9 ans). depuis 6 ans chez Suez puis récemment chez Veolia où je suis en charge du marché Pharma et cosmétique avec pour mission principale répondre aux problématiques liées au triptyque eau/énergie/déchets de nos clients et les accompagner dans la mise en place de leur roadmap environnementale et son implémentation ",
      },
      {
        id: 7,
        name: "Jean-Denis MALLET",
        photoName: "jean-denis-mallet",
        company: "PHARMAPLAN",
        job: "Directeur Compliance",
        order: "Conférence #6",
        linkedInID: "jean-denis-mallet-36061b3",
        experience:
          "Jean-Denis Mallet est docteur en pharmacie, option industrie, diplômé de la Faculté de Tours et diplômé de Audencia – Nantes. Il a exercé d’abord dans l’industrie (Roussel, Janssen, Ozothine, Ipsen) puis dans le réglementaire (Inspecteur à l’agence), dans l’humanitaire (Croix-Rouge) et maintenant dans l’ingénierie et le conseil en bonnes pratiques de fabrication.",
      },
      {
        id: 8,
        name: "Pierre DEVAUX",
        photoName: "pierre-devaux",
        company: "THERAXEL",
        job: "Directeur Scientifique",
        order: "Animation",
        linkedInID: "pierre-devaux-a6b9ba33",
        experience:
          "Pharmacien Microbiologiste, Directeur Scientifique & Co-Dirigeant depuis respectivement 2 ans  et 4 ans des sociétés ISOTEC’XEL, société spécialisée dans la maitrise de l’isotechnie depuis le lancement du projet jusqu’à la mise en service de la Technologie Barrière, et THERAXEL, société d’audit, conseil et formation dont le slogan est « La Maitrise des risques chimiques et microbiologiques au plus près du terrain », mes différentes expériences professionnelles, que ce soit chez SANOFI PASTEUR en fin de cursus universitaire à Marcy l’Etoile, chez FAREVA Excelvision, site de façonnage de liquides stériles dans une fonction de responsable CQ/Assurance de Stérilité puis de Pharmacien Responsable du laboratoire ACM Pharma et consultant pour la société de consulting UPS Consultants, m’ont permis et, notamment ces 10 dernières années, de travailler avec de nombreux sites industriels pharmaceutiques et apparentés sur des problématiques diverses de contamination et d’être confronté à la réalité du terrain. Passionné par mon métier, ce Symposium est une nouvelle expérience nous permettant de partager tous ensemble ce vécu indispensable pour assurer la Qualité de nos futurs médicaments ! Excellente journée à tous ! ",
      },
    ],
  }),
  computed: {},
  mounted() {
    let that = this;
    let x = setInterval(function () {
      const now = new Date().getTime();
      const distance = that.countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      that.countDownTimer = `${days} JOURS - ${hours} HEURES - ${minutes} MINUTES - ${seconds} SECONDES`;

      if (distance < 0) {
        clearInterval(x);
        that.countDownTimer = "";
      }
    }, 1000);

    const IVSPlayer = document.getElementById("ivsPlayer");
    IVSPlayer.async = true;

    const player = window.IVSPlayer.create();
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    player.addEventListener(PlayerState.PLAYING, function () {
      console.log("Player State - PLAYING");
    });

    player.addEventListener(PlayerState.READY, function () {
      console.log("Player State - READY");
    });

    player.addEventListener(PlayerEventType.ERROR, function (err) {
      console.warn("Player Event - ERROR:", err);
    });

    player.addEventListener(PlayerEventType.AUDIO_BLOCKED, function () {
      if (player.isMuted()) {
        player.setMuted(false);
        console.log('Player.isMuted() ? --> ' + player.isMuted())
      }
    });

    player.setAutoplay(true);
    player.load(process.env.VUE_APP_AWS_IVS_PLAYBACK_URL);
    player.setVolume(1);
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    },
    getImgUrl(pic) {
      return require("../assets/" + pic + ".jpg");
    },
    displayOrHide(container) {
      if (container === "displayProgram") {
        this.displayProgram = true;
        this.displayIntervenants = false;
      } else if (container === "displayIntervenants") {
        this.displayIntervenants = true;
        this.displayProgram = false;
      }
    },
    videoFullScreen() {
      let elem = document.getElementById("video-player");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.text-deco-none {
  text-decoration: none !important;
}

.color-bmx {
  color: #00427f;
}
.btn-link a{
  text-decoration: none;
}
.bg2 {
  margin-top: 30px;
  padding: 0;
  margin-left: 0;
  width: 100%;
  height: auto;
  background: url("../assets/bg2.jpg") no-repeat;
  background-position: center;
}

.title-block2 {
  width: 100%;
  background-color: rgba(7, 7, 7, 0.5);
  color: white;
  padding: 0px 5px 0px 5px;
}

.font-benton {
  font-family: "Benton Sans";
}

.bg-colo-bmx {
  background-color: #00427f;
}

.program-header {
  line-height: 1.5em;
}

.inner {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  margin: 0 auto;
  padding-top: 1px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.player-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  z-index: 1;
  margin-left: -30px;
}

.aspect-spacer {
  padding-bottom: 1.25%;
}

.el-player {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: var(--radius);
}

#video-player:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
</style>
